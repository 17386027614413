@font-face {
	font-family: "Gilroy-Regular";
	src: url("./assets/fonts/Gilroy/Gilroy-Regular.otf") format("opentype");
}

@font-face {
	font-family: "Gilroy-SemiBold";
	src: url("./assets/fonts/Gilroy/Gilroy-SemiBold.otf") format("opentype");
}

@font-face {
	font-family: "Gilroy-Bold";
	src: url("./assets/fonts/Gilroy/Gilroy-Bold.otf") format("opentype");
}

@font-face {
	font-family: "AvenirNextLTPro-Regular";
	src: url("./assets/fonts/AvenirNext/AvenirNextLTPro-Regular.otf")
		format("opentype");
}

@font-face {
	font-family: "AvenirNextLTPro-Bold";
	src: url("./assets/fonts/AvenirNext/AvenirNextLTPro-Bold.otf")
		format("opentype");
}
